import * as React from 'react';

function FavoriteOutline(props: any) {
  return (
    <svg height="1em" preserveAspectRatio="xMidYMid" viewBox="0 0 128 128" width="1em" {...props}>
      <path
        d="M126.772 51.913c-1.641 6.254-4.848 11.796-9.505 16.528l-41.524 48.612c-3.096 3.585-7.039 5.392-11.765 5.392-4.726 0-8.668-1.807-11.768-5.396L10.66 68.34C6.077 63.754 2.814 58.12 1.266 52.004.418 49 0 45.775 0 42.443 0 32.631 3.808 23.4 10.737 16.472 19.61 7.597 31.993 3.833 44.055 6.293c6.015 1.267 11.383 3.881 16.17 7.883a5.834 5.834 0 0 0 3.753 1.342c1.4 0 2.658-.459 3.674-1.339 9.686-7.953 22.313-10.577 34.105-7.094 5.884 1.828 11.005 4.928 15.463 9.387 9.322 9.322 12.893 22.716 9.552 35.44zm-14.428 12.012c7.721-7.721 10.73-18.85 8.013-29.263-3.259-12.157-13.877-21.773-27.765-22.377-7.712-.374-14.583 1.94-20.726 7.016-.334.223-.49.33-.73.511a11.431 11.431 0 0 1-4.092 1.923c-1.093.273-2.062.412-3.066.412-2.319 0-5.33-1.013-6.809-1.998-.37-.247-.701-.496-1.228-.902-5.627-4.652-11.977-6.96-19.156-6.96a30.137 30.137 0 0 0-21.323 8.825c-5.7 5.776-8.834 13.336-8.834 21.331 0 8.072 3.133 15.63 8.878 21.379l41.75 48.913c1.77 2.055 3.998 3.082 6.722 3.082s4.95-1.026 6.722-3.08l41.644-48.812z"
        fill="currentColor"
      />
    </svg>
  );
}

export default FavoriteOutline;
